<template>
  <v-layout wrap row pa-3>
    <v-flex md4 xs12 offset-md-1>
      <vue-cropper
        ref="cropper"
        :aspectRatio="1/1"
        :guides="true"
        :view-mode="3"
        drag-mode="crop"
        :auto-crop-area="0.5"
        :min-container-width="300"
        :min-container-height="300"
        :background="true"
        :rotatable="false"
        :src="file"
        alt="Source Image"
      />
      <!-- <img :src="file" ref="cropper" style="height: 60vh;" /> -->
    </v-flex>
    <v-flex md4 xs12 offset-md-1>
      <v-form ref="form" v-model="valid">
        <v-layout wrap row px-4>
          <v-flex xs12>
            <v-text-field
              v-model="company.companyName"
              prepend-icon="home"
              name="companyName"
              :rules="nameRules"
              label="Name"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="company.address"
              :rules="addressRules"
              name="companyAddress"
              prepend-icon="place"
              label="Address"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="company.phoneNo"
              prepend-icon="phone"
              name="companyPhone"
              :rules="phoneRules"
              label="Phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="company.website"
              prepend-icon="language"
              name="companyWebsite"
              :rules="websiteRules"
              label="Website"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="company.email"
              :rules="emailRules"
              prepend-icon="mail"
              name="companyEmail"
              label="Email"
            ></v-text-field>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              :append-icon="show2 ? 'visibility' : 'visibility_off'"
              :type="show2 ? 'text' : 'password'"
              @click:append="show2 = !show2"
              v-model="company.password"
              :rules="passwordRules"
              name="companyPassword"
              prepend-icon="lock"
              label="Password"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 pt-3>
            <input name="file" type="file" id="file" ref="file" @change="getImagePreview" />
          </v-flex>
          <v-flex xs12 pt-4>
            <v-btn block depressed color="primary" :disabled="!valid" @click="updateCompanyProfile">
              <v-icon>edit</v-icon>
              <span class="ml-2">Update</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-form>
    </v-flex>
  </v-layout>
</template>
<script>
import companyInfoService from "../service/CompanyInfoService";
import axios from "../config";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
// import { setInterval } from "timers";

export default {
  data() {
    return {
      show2: false,
      updateImage: false,
      company: {},
      file: "",
      valid: true,
      nameRules: [v => !!v || "Company Name is required"],
      addressRules: [v => !!v || "Company Address is required"],
      websiteRules: [v => !!v || "Company Website is required"],
      phoneRules: [v => !!v || "Company Phone is required"],
      passwordRules: [v => !!v || "Company's mail password is required"],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      cropper: null
    };
  },
  mounted: function() {
    this.getCompanyInfo();
  },
  methods: {
    updateCompanyProfile: function() {
      var formData = new FormData();
      formData.append("companyName", this.company.companyName);
      formData.append("phoneNo", this.company.phoneNo);
      formData.append("address", this.company.address);
      formData.append("website", this.company.website);
      formData.append("email", this.company.email);
      formData.append("password", this.company.password);
      if (this.updateImage) {
        let blob = this.dataURLtoBlob(
          this.$refs.cropper.getCroppedCanvas().toDataURL()
        );
        formData.append("file", blob);
        formData.append("logo", "");
      } else {
        formData.append("logo", this.company.logo);
      }
      companyInfoService
        .updateCompanyProfile(formData, this.company.companyProfileId)
        .then(() => {
          this.$swal("Successful", "Changing Profile", "success");
        })
        .catch(err => {
          this.$swal("Failed", err.response.data.message, "error");
        });
    },
    getCompanyInfo: function() {
      companyInfoService.getCompanyInfo().then(response => {
        this.company = Object.assign({}, response);
        this.file = `${axios.defaults.baseURL}/companyLogo/${response.logo}`;
      });
    },
    dataURLtoBlob: function(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    getImagePreview: function(e) {
      let image = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.file = e.target.result;
        this.$refs.cropper.replace(event.target.result);
      };
      this.updateImage = true;
    }
  },
  components: {
    VueCropper
  }
};
</script>

<style>
@import "~cropperjs/dist/cropper.min.css";
</style>